import {Link} from "gatsby";
import React, {FunctionComponent as FC} from "react";
import styled from "styled-components";

const StyledButton = styled.a`
  background-image: linear-gradient(to right, #3ab1e6, #b63e8f 90%);
`;

const StyledLink = styled(Link)`
  background-image: linear-gradient(to right, #3ab1e6, #b63e8f 90%);
`;

type CtaButtonProps = {url: string; caption: string; className?: string};

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const CtaButton: FC<CtaButtonProps> = ({url, caption, className = ""}) => {
  if (isValidHttpUrl(url)) {
    return (
      <StyledButton className={`btn px-5 ${className}`} href={url} target="__blank">
        {caption}
      </StyledButton>
    );
  } else if (/^#/.test(url)) {
    return (
      <StyledButton className={"btn px-5 " + className} href={url}>
        {caption}
      </StyledButton>
    );
  }

  return (
    <StyledLink to={url} className={"btn px-5 " + className}>
      {caption}
    </StyledLink>
  );
};
