import React from "react";
import styled from "styled-components";

const Divider = styled.div<TDivider>`
  width: 100%;
  border-bottom: ${(props) => (props.isSelected ? "2px" : "1px")} solid ${(props) => (props.color)};
  opacity: ${(props) => (props.isSelected ? "1" : "0.5")};
  height: 1px;
  margin: ${(props) => (props.margin ? "5px 0 8px 0" : "0")};
`;

type TDivider = {
  margin?: boolean;
  className?: string;
  isSelected?: boolean;
  color?: string;
};

export default ({
  margin = true,
  className = "",
  isSelected = false,
  color = "white"
}: TDivider) => (
  <Divider margin={margin} isSelected={isSelected} className={className} color={color}/>
);
