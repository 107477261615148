import {Link} from "gatsby";
import React, {FunctionComponent} from "react";
import styled from "styled-components";
// @ts-ignore
import BeenhereIcon from "./beenhere@2.png";
import "./index.css";

export enum ButtonColor {
  Turquoise = "turquoise",
  Violet = "violet",
  none = "none"
}

export enum Icon {
  Beenhere = BeenhereIcon
}

export enum ButtonBackground {
  gradient = "gradient"
}

type GradientProp = {
  $gradient: boolean;
  onClick?: (e) => void;
};

const StyledLink = styled(Link)<GradientProp>`
  ${({$gradient}) =>
    $gradient &&
    `
      background-image: linear-gradient(to right, #3ab1e6 , #b63e8f 90%);
  `}
`;

const StyledA = styled.a<GradientProp>`
  ${({$gradient}) =>
    $gradient &&
    `
      display: inline-block;
      width: auto;
      height: auto;
      background-image: linear-gradient(to right, #3ab1e6 , #b63e8f 90%);
  `}
`;

type ButtonProps = {
  to?: string;
  previousPath?: string;
  goBack?: boolean;
  icon?: Icon;
  color?: ButtonColor;
  className?: string;
  external?: boolean;
  gradient?: boolean;
  style?: React.CSSProperties;
  action?: (e) => void;
  /* Shows a progress spinner in the button and disables it while true. */
  isProcessing?: boolean;
  disabled?: boolean;
};

/**
 * This button class should be used to render a rounded colored button.
 * If goBack is set the user is routed to the previous page.
 * Else the `to` path will be prefixed with the current locale to
 * ensure consistent routing.
 */
const Button: FunctionComponent<ButtonProps> = ({
  children,
  to,
  goBack,
  previousPath,
  icon = null,
  color = ButtonColor.Turquoise,
  className = "",
  external = false,
  action = undefined,
  style = {},
  gradient = false,
  isProcessing = false,
  disabled = false
}) => {
  let loc =
    typeof location !== "undefined" ? location : {pathname: "/", search: ""};

  const spinner = <span className="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden={true}>
    <span className="sr-only">Loading...</span>
  </span>

  let isProcessingSpinner = <>
    {isProcessing && spinner}
  </>;

  if (disabled) {
    className += " disabled";
  }

  if (goBack) {
    return (
      <StyledLink
        to={previousPath ? previousPath : "/"}
        className={`btn btn-${color} text-uppercase ${className}`}
        $gradient={gradient}
        style={style}
        onClick={action ? action : undefined}
      >
        {icon && <img className="mr-2 btn-icon" src={icon.toString()} alt="" />}
        {children}
        {isProcessingSpinner}
      </StyledLink>
    );
  } else if (external) {
    return (
      <StyledA
        href={to}
        className={`btn btn-${color} text-uppercase ${className}`}
        $gradient={gradient}
        target="_blank"
        style={style}
      >
        {icon && <img className="mr-2 btn-icon" src={icon.toString()} alt="" />}
        {children}
        {isProcessingSpinner}
      </StyledA>
    );
  } else if (action) {
    return (
      <StyledA
        onClick={action}
        className={`btn btn-${color} text-uppercase ${className}`}
        $gradient={gradient}
        style={style}
      >
        {icon && <img className="mr-2 btn-icon" src={icon.toString()} alt="" />}
        {children} 
        {isProcessingSpinner}
      </StyledA>
    );
  } else {
    return (
      <StyledLink
        to={to}
        state={{previousPath: loc.pathname + loc.search}}
        className={`btn btn-${color} text-uppercase ${className}`}
        $gradient={gradient}
        style={style}
      >
        {icon && <img className="mr-2 btn-icon" src={icon.toString()} alt="" />}
        {children}
        {isProcessingSpinner}
      </StyledLink>
    );
  }
};

export default Button;
