import React, {FunctionComponent as FC} from "react";
import ReactMarkdown from "react-markdown";
import {isValidHttpUrl} from "./heroarea/cta-button";
import styled from "styled-components";

type MarkdownProps = {
  content: string;
  enableTargetBlank?: boolean;
  whiteLinks?: boolean;
};

type StyleProps = {
  whiteLinks: boolean;
};

const StyledA = styled.a<StyleProps>`
  ${({whiteLinks}) =>
    whiteLinks
      ? `
      color: white;
      text-decoration: none;
      &:hover {
        color: lightgray;
      }
      font-size: 1.9rem !important;
      @media (max-width: 768px) {
        font-size: 1.25rem !important;
      }`
      : `color: #00b7fa !important;`}
`;

/**
 * This component takes a markdown string and unescapes the
 * (by firestore) escaped newlines. Also it defines how images
 * are rendered.
 */
export const Markdown: FC<MarkdownProps> = ({content, whiteLinks = false, enableTargetBlank = false}) => {
  const unescapeNewlines = (str) => {
    return str.replace(new RegExp(/\\n/, "g"), "\n");
  };

  const ImgRenderer = ({node, ...props}) => <img style={{maxWidth: "100%"}} {...props} />;
  const LinkRenderer = ({node, ...props}) => {
    return (
      <StyledA
        target={enableTargetBlank || isValidHttpUrl(node.properties.href) ? "_blank" : "_self"}
        whiteLinks={whiteLinks}
        {...props}
      />
    );
  };

  return (
    <ReactMarkdown
      children={unescapeNewlines(content)}
      components={{
        img: ImgRenderer,
        a: LinkRenderer
      }}
    />
  );
};
